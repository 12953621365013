import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'vendorHoldRelease',
  components: { DatePicker },
  watch: {
    currentPage: function() {
      this.getvenHoldRelDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getvenHoldRelDtls();
    }
  },
  data() {
    return {
      loading: false,
      showValueSetModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      legalEntity: {
        text: null,
        value: null
      },
      vendorName: null,
      holdStatus: {
        text: null,
        value: null
      },
      creationFromDate: null,
      creationToDate: null,
      unsubscribe: null,
      venHoldRelData: [],
      venHoldRelFields: [
        {
          key: 'org_name',
          label: 'Legal Entity Name',
          class: 'text-center'
        },
        {
          key: 'fp_gst',
          label: 'FP GST',
          class: 'text-center'
        },
        {
          key: 'tp_gst',
          label: 'TP GST',
          class: 'text-center'
        },
        {
          key: 'invoice_number',
          label: 'Invoice Number',
          class: 'text-center'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          class: 'text-center'
        },
        {
          key: 'vendor_site_name',
          label: 'Vendor Site',
          class: 'text-center'
        },
        {
          key: 'vendor_hold_criteria',
          label: 'Vendor Hold Criteria',
          class: 'text-center'
        },
        {
          key: 'unpaid_amount',
          label: 'Unpaid Amount',
          class: 'text-center'
        },
        {
          key: 'tax_amount',
          label: 'Tax Amount',
          class: 'text-center'
        },
        {
          key: 'creation_date_hold',
          label: 'Hold Date & Time',
          class: 'text-center'
        },
        {
          key: 'unhold_Date',
          label: 'Unhold Date & Time',
          class: 'text-center'
        }
      ],
      payload: null
    };
  },
  validations: {
    legalEntity: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loading = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'emailler/getvenHoldRelDtls',
            'vendor-hold-release',
            () => (this.loading = false)
          );
        }
      }
    });
  },
  methods: {
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.creationFromDate);
    },
    getvenHoldRelDtls() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          name: this.vendorName,
          period_from: this.creationFromDate,
          period_to: this.creationToDate
        };
        this.loading = true;
        this.$store
          .dispatch('emailler/getvenHoldRelDtls', this.payload)
          .then(resp => {
            this.loading = false;
            if (resp.status === 200) {
              this.venHoldRelData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        this.holdStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.text) {
        this.legalEntity = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.holdStatus.text) {
        this.holdStatus = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'vendorName') {
        this.vendorName = null;
      }
    },
    clearFilters() {
      this.legalEntity = {
        text: null,
        value: null
      };
      this.vendorName = null;
      this.holdStatus = {
        text: null,
        value: null
      };
      this.creationFromDate = null;
      this.creationToDate = null;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
